import React, { useState } from 'react';
import './App.css';
import logo from './logo.jpeg';
import menu from './menu.png';
import cabbage from './cabbage.webp';
import pancake from './pancake.webp';
import pierogie from './pierogie.webp';
import potatoes from './potatoes.webp';
import deli from './deli.jpeg';
import freezer from './freezer.jpeg';
import dessert from './dessert2.JPG';
import dessert2 from './dessert.JPG';
import dessert3 from './dessert3.JPG';
import peppers from './peppers.JPG';
import pies from './pies.JPG';
import sweets from './sweets.jpeg';
import yummy2 from './yummy2.jpeg'


function App() {
  const [showMenu, setShowMenu] = useState(false);
  const [showRecipe, setShowRecipe] = useState(false); // Add this

  const toggleMenu = () => setShowMenu(!showMenu);

  const closeModal = (e) => {
    if (e.target.className === 'modal') {
      setShowMenu(false);
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} alt="Bazar Market & Grill" className="logo" />
      </header>
      <section className="about-us">
        <h2>Now OPEN in Parma Heights!</h2>
        <p>We bring traditional European flavors to you with our market and grill. Shop for hard-to-find European goods like candies, pastas, teas, and sausages in our market. Then enjoy freshly prepared meals at our grill for a true taste of home. Experience both in one place, right here in Parma Heights, OH.</p>
        <div className="button-group">
          <button className="cta-button" onClick={toggleMenu}>VIEW MENU</button>
          <button className="cta-button call-button" onClick={() => window.location.href = 'tel:+14405582413'}>440-558-2413</button>
          <button className="cta-button catering-button" onClick={() => window.location.href = 'mailto:bazarandgrill@gmail.com?subject=Catering Inquiry'}>EMAIL CATERING</button>
          <button className="cta-button location-button" onClick={() => window.open('https://www.google.com/maps/search/?api=1&query=6619+Pearl+Rd+Parma+Heights+OH+44130', '_blank')}>6619 Pearl Rd</button>
        </div>
      </section>
      {showMenu && (
        <div className="modal" onClick={closeModal}>
          <div className="modal-content">
            <span className="close" onClick={() => setShowMenu(false)}>&times;</span>
            <img src={menu} alt="Menu" className="menu-image" />
          </div>
        </div>
      )}
      <section className="gallery">
        <img src={cabbage} alt="Cabbage Rolls" className="gallery-img" />
        <img src={pancake} alt="Potato Pancake" className="gallery-img" />
        <img src={pierogie} alt="Pierogies" className="gallery-img" />
        <img src={potatoes} alt="Roasted Potatoes" className="gallery-img" />
      </section>

      {/* New Section for Visit our Market */}
      <section className="market">
        <h2>Visit our Market @ 6619 Pearl Rd Parma Heights, OH 44130</h2>

        {/* Business Hours Container */}
        <div className="business-hours-container">
          <h3><span className="clock-icon">🕒</span> Hours of Business</h3>
          <p>Monday - Saturday: 10am - 7pm</p>
          <p>Sunday: 10am - 5pm</p>
        </div>

        <div className="market-section">
          <h3>European Fresh Deli</h3>
          <div className="market-gallery">
            <img src={deli} alt="Deli Meats and Cheeses" className="market-img" />
            <img src={peppers} alt="Peppers" className="market-img" />
            <img src={pies} alt="Pies" className="market-img" />
                      </div>
        </div>

        <div className="market-section">
          <h3>European Grocery Store</h3>
          <div className="market-gallery">
            <img src={freezer} alt="European Grocery Store" className="market-img" />
            <img src={sweets} alt="Sweets" className="market-img" />
            <img src={yummy2} alt="Yummy Treats" className="market-img" />
          </div>
        </div>

        <div className="market-section">
          <h3>Homemade Desserts</h3>
          <div className="dessert-gallery">
            <img src={dessert} alt="Homemade Dessert" className="market-img" />
            <img src={dessert2} alt="Homemade Dessert" className="market-img" />
            <img src={dessert3} alt="Homemade Dessert" className="market-img" />
          </div>
        </div>
      </section>

  
      <footer>
        <p>© 2024 Bazar Market & Grill. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default App;
